export default class Newsletter {
  constructor(el) {
    this.el = el;

    el.addEventListener("click", function (event) {
      event.preventDefault();
      document.getElementById("mc_embed_signup").classList.toggle("open");
    });
  }
}
