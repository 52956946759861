import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";

export default class ToggleCaption extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.props.initPopoverClass = refs.popover.className;

    this.update();
    this.bindToggles();
  }

  update = () => {
    const { popover } = this.refs;
    const { popoverToggle } = this.refs;
    const popoverClass = this.props.initPopoverClass;
    popover.className = classNames(popoverClass, {
      open: this.state.open,
    });
    Array.from(popoverToggle).forEach((toggle) => {
      toggle.setAttribute("aria-expanded", this.state.open);
    });
  };

  bindToggles() {
    const { popoverToggle } = this.refs;
    Array.from(popoverToggle).forEach((toggle) => {
      toggle.addEventListener("click", this.handleToggle);
    });
    window.addEventListener("keyup", (e) => {
      this.closeOnEscape(e);
    });
  }

  closeOnEscape(e) {
    let isEsc = false;

    if ("key" in e) {
      isEsc = e.key === "Escape" || e.key === "Esc";
    } else {
      isEsc = e.keyCode === 27 || e.which === 27;
    }

    if (isEsc) {
      this.setState({ open: false });
    }
  }

  handleToggle = (e) => {
    e.stopPropagation();
    this.setState({ open: !this.state.open });
  };
}
