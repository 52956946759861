export default class ScrollIntoView {
  constructor(el) {
    this.el = el;
    this.url = new URL(el.href);
    if (!this.url || !this.url.hash) return null;
    this.bindEvents();
  }

  bindEvents() {
    this.el.addEventListener("click", this.scrollToDestination);
  }

  scrollToDestination = (event) => {
    const destination = document.querySelector(this.url.hash);
    if (!destination) return null;
    event.preventDefault();
    destination.scrollIntoView({ behavior: "smooth" });
  };
}
