import ControlPopovers from "./ControlPopovers";
import OpenOverlay from "./OpenOverlay";
import SetCSSProperties from "./SetCSSProperties";
import SetFooterBgColor from "./SetFooterBgColor";
import TogglePopover from "./TogglePopover";

export default {
  ControlPopovers,
  OpenOverlay,
  SetCSSProperties,
  SetFooterBgColor,
  TogglePopover,
};
