/*
Creates and updates the values of CSS custom properties from DOM measurements
*/
import Sb from "../abstract/StatefulBehavior";
import debounce from "lodash/debounce";

export default class SetCSSProperties extends Sb {
  constructor(el) {
    super();

    this.wpAdminBar = document.querySelector("#wpadminbar");

    if (!this.wpAdminBar) return;

    this.setAdminBarHeight();
    this.bindResize();
  }

  setAdminBarHeight = () => {
    const adminBarHeight = this.wpAdminBar.getBoundingClientRect().height;

    document.documentElement.style.setProperty(
      "--admin-bar-height",
      `${adminBarHeight}px`
    );
  };

  bindResize() {
    // Set overlay to closed state if at wider viewport width
    const debouncedClientSize = debounce(() => this.setAdminBarHeight(), 200);

    window.addEventListener("resize", debouncedClientSize);
  }
}
