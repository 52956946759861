import FilterByTag from "./FilterByTag";
import LazyLoad from "./LazyLoad";
import ScrollIntoView from "./ScrollIntoView";
import ToggleCaption from "./ToggleCaption";
import Newsletter from "./Newsletter";

export default {
  FilterByTag,
  LazyLoad,
  ScrollIntoView,
  ToggleCaption,
  Newsletter,
};
