/*
Sets footer background color based on color of last child of `<main>`
*/

export default class SetFooterBgColor {
  constructor(el) {
    this.el = el;

    this.palette = {
      white: "a-bg-white",
      secondary: "a-bg-secondary-pale",
    };

    this.setFooterColor();
  }

  get lastBodyChild() {
    const main = document.querySelector("main");
    return main.lastElementChild;
  }

  get isLastBodyColorSecondary() {
    return (
      this.lastBodyChild &&
      this.lastBodyChild.classList.contains(this.palette.secondary)
    );
  }

  setFooterColor() {
    const { white, secondary } = this.palette;
    const footerColor = this.isLastBodyColorSecondary ? white : secondary;

    this.el.classList.add(footerColor);
  }
}
