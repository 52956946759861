export default class FilterByTag {
  constructor(el, props, refs) {
    this.el = el;
    this.refs = refs;

    if (!this.refs.select) return null;

    this.addListeners();
  }

  addListeners() {
    this.refs.select.addEventListener("change", this.handleChange);
  }

  handleChange = (event) => {
    event.preventDefault();
    window.location.href = event.target.value;
  };
}
