import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";

export default class TogglePopover extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.props.initPopoverClass = refs.popover.className;

    this.update();
    this.bindClicks();
  }

  update = () => {
    const { popover } = this.refs;
    const { popoverToggle } = this.refs;
    const popoverClass = this.props.initPopoverClass;
    popover.className = classNames(popoverClass, {
      "is-open": this.state.open,
      "is-closed": !this.state.open,
    });
    Array.from(popoverToggle).forEach((toggle) => {
      toggle.setAttribute("aria-expanded", this.state.open);
    });
    const focusable = popover.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    focusable.forEach((f) => {
      f.setAttribute("tabIndex", !this.state.open ? -1 : 0);
    });
  };

  bindClicks() {
    const { popoverToggle } = this.refs;
    document.addEventListener("click", this.handleDocumentClick);
    Array.from(popoverToggle).forEach((toggle) => {
      toggle.addEventListener("click", this.handleToggle);
    });
    window.addEventListener("keyup", (e) => {
      this.closeOnEscape(e);
    });
  }

  closeOnEscape(e) {
    let isEsc = false;

    if ("key" in e) {
      isEsc = e.key === "Escape" || e.key === "Esc";
    } else {
      isEsc = e.keyCode === 27 || e.which === 27;
    }

    if (isEsc) {
      this.close();
    }
  }

  handleDocumentClick = (event) => {
    if (event.target.closest(`[data-behavior="ControlPopovers"]`)) return null;

    this.state.open && this.close();
  };

  handleToggle = (e) => {
    e.stopPropagation();
    this.setState({ open: !this.state.open });
    this.props.onClick(this.props.id);
  };

  close() {
    this.setState({ open: false });
  }
}
